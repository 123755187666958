.cover {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .4);
    z-index: 10;
}

.cover .pop_up {
    position: fixed;
    width: 510px;
    border-radius: 24px;
    top: calc(50vh - 200px);
    left: calc(50% - 250px);
    background-color: #FFF;
    padding: 30px 40px;
    color: #000;
}

.cover .logo {
    position: absolute;
    left: 36px;
    top: 31px;
    height: 34px;
}

.cover .metabank_typo {
    font-family: 'Raleway-extrabold';
    font-size: 30px;
    text-align: left;
    margin-left: 45px;
}

.cover p {
    font-family: 'Raleway-medium';
    font-size: 13px;
}

.cover p a {
    font-family: 'Raleway-semibold';
    color: var(--primary);
    text-decoration: none;
}

@media screen and (max-width: 940px) {
    .cover .pop_up {
        position: fixed;
        width: calc(96% - 90px);
        border-radius: 24px;
        top: 3%;
        left: 3%;
    }

    .cover button {
        margin-right: 0 !important;
    }
}