button {
    font-family: 'Raleway-bold';
    font-size: 16px;
    text-align: left;
    margin-right: 50px;
    cursor: pointer;
    color: #FFF;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    text-decoration: none;
    height: 40px;
    line-height: 36px;
    border-radius: 20px;
    padding: 0 18px;
    margin-top: 30px;
}

button:hover {
    background-color: var(--primary-hover);
    border: 2px solid var(--primary-hover);
}

.framed {
    color: var(--primary) !important;
    background-color: transparent !important;
}

.framed:hover {
    color: var(--primary-hover) !important;
    background-color: transparent !important;
}



/* OVERIDES */
.Toastify button{
    margin: auto !important;
}
.Toastify button:hover{
    background-color: rgba(0, 0, 0, 0) !important;
}